<!-- 语法 -->
<template>
    <div class="">

        <div style="width:300px;height:300px;margin:0 auto;font-size:1.3em;">
            联系客服！
            <img src="@/assets/jpg/wei.jpg" alt="">
        </div>
        <kffooter></kffooter>
    </div>
</template>

<script>
   import kffooter from "@/components/Footer.vue";
    export default {
        name: '',
        components: {
            kffooter
        },
        data() { // 数据源
            return {
            }
        },
        mounted() {

        },
        computed: {
         
        },
        methods: { // 函数体部分，js的主要逻辑控制               
        },
        props: {
        }
    }
</script>

<style scoped>
    /* @import './swiper.css'; */
    img{
        width:100%;
    }
</style>